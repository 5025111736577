@import '_shared/style/text.scss';

@mixin link {
  @include text_body;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  height: 100%;
  padding: 1.5rem 0.5rem 0 0.5rem;
  text-decoration: none;
  cursor: pointer;

  .title {
    color: RGB(var(--theme_nav_text));
    text-align: center;
    align-self: stretch;
  }

  & > *:first-child {
    margin-bottom: 0.5rem;
    height: 1.25rem;
  }
}

@mixin indicator {
  content: '';
  position: absolute;
  bottom: 0.75rem;
  width: 1.5rem;
  left: calc(50% - 0.75rem);
  height: 2px;
  background-color: RGB(var(--theme_nav_border));
}

.layout {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  height: 7rem;
  padding: 0 2rem;
  background-color: RGB(var(--theme_background_nav));
  transition: height 0.25s ease-out;

  .logo {
    flex-grow: 0;
    flex-shrink: 0;
    align-self: center;
    margin-right: auto;

    svg {
      width: 16rem;
    }
  }

  &.collapsed {
    height: 3.5rem;

    .logo svg {
      width: 10rem;
    }
  }
}

.navgroup {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.wrapper {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  padding: 0;
  width: 5.5rem;
  border-left: 1px solid RGB(var(--theme_nav_border));

  &:hover .subNav {
    display: flex;
  }

  &:last-child {
    border-right: 1px solid RGB(var(--theme_nav_border));
  }
}

.link {
  @include link;

  &.collapsed {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }

  .titleInactive {
    color: RGBA(var(--theme_nav_text), 0.5);
    text-align: center;
    align-self: stretch;
  }

  .locked {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
}

.linkActive {
  @include link;

  &.collapsed {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }

  &:after {
    @include indicator;
  }
}

.subNav {
  position: absolute;
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
  width: auto;
  height: 7.5rem;
  top: 100%;
  left: 50%;
  margin: 0;
  padding: 0;
  background-color: RGBA(var(--theme_background_nav), 0.1);
  list-style-type: none;
  transform: translateX(-50%);
  z-index: 3;

  .wrapper {
    border-color: RGBA(var(--theme_background_nav), 0.1);
  }
}

.logout {
  .name {
    color: RGB(var(--theme_nav_text));
    text-align: center;
    margin: 0;
  }

  .role {
    color: RGB(var(--theme_nav_icon));
    text-align: center;
    margin: 0.25rem 0 0 0;
  }
}