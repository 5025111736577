@import '_styles/colors.scss';

.layout {
  transition: filter 0.5s ease-out;
  height: 100vh;
  overflow: hidden;
  background-color: RGB(var(--theme_background_ui));

  .overlay {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
  }

  .busy {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 40;
    pointer-events: all;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);

    .loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 6rem;
      height: 6rem;
      padding: 1rem;
      background-color: $color_ui_background;
      border-radius: 0.75rem;

      svg {
        width: 100%;
      }
    }
  }
}