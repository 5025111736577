@import '_styles/colors.scss';

.layout {
  .action {
    flex-grow: 0;
    flex-shrink: 0;
    width: 2.25rem;
    height: 2.25rem;
    background-color: $color_text;

    &.ghosted {
      opacity: 0.3;
    }
  }

  .shifter {
    clip-path: inset(0 0 -100% 0);
  }

  .controller {
    padding: 1rem 0;

    .title {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
    }
  }
}