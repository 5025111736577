@import '_styles/colors.scss';
@import '_styles/text.scss';
@import '_styles/boxes.scss';

.field {
  max-width: 20rem;
  min-height: auto;

  .input {
    margin: 0.25rem;
    @include text_caption;
  }
}

.select {
  height: 1.5rem;
  max-height: 1.5rem;
}

.checkBoxLabel {
  @include text_body;
  margin-left: 0.5rem;
  cursor: pointer;
}

.removeBtn {
  flex-grow: 0;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0 0 auto;
  padding: 0;
  background-color: transparent;
}
