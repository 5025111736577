@import '_styles/colors.scss';

/*

const allowed = [
  'brand',
  'warning',
  'caution',
  'disabled',
  'negative'
]

*/

@mixin negative {
  min-height: 2.5rem;
  padding: 0 1.5rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 1rem 1rem;
    border-color: transparent transparent rgba(255, 255, 255, 0.5) transparent;
  }
}

.layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  border-width: 0;
  background-color: transparent;
  cursor: pointer;

  &.negative_brand {
    @include negative;
    background-color: $color_brand;
  }

  &.negative_warning {
    @include negative;
    background-color: $color_warning;
  }

  &.negative_caution {
    @include negative;
    background-color: $color_delete;
  }

  &.negative_disabled {
    @include negative;
    background-color: $color_disabled;
  }

  & > *:first-child {
    margin-right: 0.5rem;
  }

  .label_brand {
    color: $color_brand;
  }

  .label_warning {
    color: $color_warning;
  }

  .label_caution {
    color: $color_delete;
  }

  .label_disabled {
    color: $color_disabled;
  }

  .label_negative {
    color: $color_text_light;
  }
}