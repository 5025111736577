.layout {
  .field {
    min-height: 3rem;

    & > *:first-child {
      margin-left: 0.25rem;
    }
  }

  .derived {
    min-height: auto;

    & > *:first-child {
      margin-left: 0.25rem;
    }
  }

  .input {
    margin: 0.25rem 0.25rem 0.25rem 0;
    text-align: center;
  }
}