@import '../../../../style/interaction.scss';

.layout {
  position: absolute;
  flex-grow: 0;
  bottom: -3rem;
  left: 4rem;
  right: 4rem;
  width: auto;
  background-color: RGB(var(--theme_ui_module));
  border-radius: 0.25rem;
  pointer-events: all;
  transform: translateY(0);
  transition: transform 0.5s ease-in;
  overflow: hidden;
  @include card_shadow;

  &.out {
    transform: translateY(0);
  }

  & > * {
    flex-grow: 0;
  }

  .content {
    width: 100%;
    height: 3rem;
    padding: 0 2rem;

    &:hover {
      opacity: 0.5;
    }

    &.save {
      background-color: RGB(var(--theme_button_success));
    }

    &.edit {
      background-color: RGB(var(--theme_button_warning));
    }

    &.remove {
      background-color: RGB(var(--theme_button_stop));
    }

    .message {
      color: RGB(var(--theme_text_light));
      font-weight: bold;
      font-size: 1.2rem;
      margin-right: auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@keyframes slidein {
  from {
    transform: translateY(6rem);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideout {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(6rem);
  }
}