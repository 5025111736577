@import '../../../style/text.scss';
@import '../../../style/interaction.scss';

.button {
  @include text_body;
  color: RGB(var(--theme_text_light));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 0 0.75rem;
  outline: 0;
  border-width: 0;
  background-color: RGB(var(--theme_button));
  @include button_hover;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 1rem 1rem;
    border-color: transparent transparent RGBA(var(--theme_text_light), 0.5) transparent;
  }
}