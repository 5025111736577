@import '_styles/text.scss';

.layout {
  overflow: hidden;

  svg {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .text {
    @include text_body;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .textReverse {
    @include text_body;
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}