.layout {
  padding: 1rem;
  border-width: 1px;
  border-style: dashed;
  border-color: RGB(var(--theme_text));

  &.success {
    background-color: RGBA(var(--theme_text_success), 0.1);
    border-color: RGB(var(--theme_text_success));
  }

  &.warning {
    background-color: RGBA(var(--theme_text_warning), 0.1);
    border-color: RGB(var(--theme_text_warning));
  }
}