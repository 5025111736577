@import '_styles/colors.scss';
@import '_styles/text.scss';
@import '_styles/boxes.scss';

.layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;

  .caddy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    animation-duration: 0.5s;
    animation-name: slidein;
    animation-fill-mode: forwards;

    &.out {
      animation-duration: 0.5s;
      animation-name: slideout;
      animation-fill-mode: forwards;
    }

    .content {
      position: absolute;
      right: 0;
      bottom: 0;
      width: calc(100vw);
      height: calc(100vh - 3.5rem);
      background-color: RGB(var(--theme_background_ui));
      @include scroll_bar;
    }

    .scroller {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      @include scroll_bar;
    }
  }
}

.controls {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  bottom: 2rem;
  right: 2rem;
  width: auto;
  padding: 1rem;
  background-color: RGB(var(--theme_background_module));
  transition: transform 0.5s ease-in;
  transform: translateY(8rem);
  @include modal_shadow;

  & > * {
    align-self: stretch;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .required {
    position: absolute;
    top: -1.5rem;
    left: 0;
    right: 0;
    font-size: 0.75rem;
    padding: 0.25rem;
    height: 1.5rem;
    text-align: center;
    background-color: RGB(var(--theme_background_module));
  }

  .lockMessage {
    text-align: center;
  }

  &.in {
    animation-duration: 0.5s;
    animation-name: floatInAction;
    animation-fill-mode: forwards;
  }

  &.out {
    animation-duration: 0.5s;
    animation-name: floatOutAction;
    animation-fill-mode: forwards;
  }
}

@keyframes floatInAction {
  from {
    transform: translateY(8rem);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes floatOutAction {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(8rem);
  }
}

@keyframes slidein {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideout {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}
