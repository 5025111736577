@import '_styles/boxes.scss';
@import '_styles/colors.scss';

@mixin slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: stretch;
  transition: margin-left 0.25s ease-out;
}

.caddy {
  display: flex;

  .slide_1 {
    @include slide;
    width: calc(100% / 1);
  }

  .slide_2 {
    @include slide;
    width: calc(100% / 2);
  }

  .slide_3 {
    @include slide;
    width: calc(100% / 3);
  }

  .slide_4 {
    @include slide;
    width: calc(100% / 4);
  }
}