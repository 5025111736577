@import '_styles/colors.scss';

.layout {
  margin-bottom: 4rem;
}

.field {
  width: 33%;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.selector {
  .row_start {
    height: 2.75rem;
    padding: 0 1.5rem;
    border-bottom: 1px solid $color_list_cell_border;
    border-top: 1px solid rgb(60,60,59);
    background-color: $color_list_back;

    .text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .row {
    height: 2.75rem;
    padding: 0 0.5rem;
    border-bottom: 1px solid $color_list_cell_border;

    .select {
      flex-grow: 0;
      flex-shrink: 0;
      width: 12rem;
      margin: 0;
      align-self: center;
      max-height: 1.5rem;
    }

    & > *:last-child {
      margin-right: 1.5rem;
    }

    &.disabled {
      & > *:last-child {
        opacity: 0.2;
      }
    }
  }
}