.layout {
  .holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    .button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: rgb(255, 255, 255);
      font-size: 1.5rem;
      font-weight: bold;
      height: 10rem;
      width: 10rem;
      background-image: linear-gradient(to bottom, rgb(169, 10, 10), rgb(255, 0, 0));
      border-radius: 50%;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3), inset 0 0.25rem 1px 1px rgb(255, 0, 0), inset 0px -0.25rem 1px 1px rgba(151, 7, 7, 0.5);
      cursor: pointer;

      &:hover {
        box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.3), inset 0 0.25rem 1px 1px rgb(255, 0, 0), inset 0px -0.25rem 1px 1px rgba(151, 7, 7, 0.5);
      }
    }
  }
}