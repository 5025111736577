.layout {
  display: block;
  width: 100%;

  &.spread {
    & > * {
      margin-bottom: 1rem;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }
}