@mixin heading {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  line-height: unset;
}

h1.section {
  @include heading;
  font-family: var(--font_secondary);
  color: RGB(var(--theme_heading_section));
  font-size: 3rem;
  font-weight: var(--font_section_weight);
  margin: 0 0 3rem 0;
}

h1.heading {
  @include heading;
  font-family: var(--font_secondary);
  color: RGB(var(--theme_heading_1));
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

h2.heading {
  @include heading;
  font-family: var(--font_secondary);
  color: RGB(var(--theme_heading_2));
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

h3.heading {
  @include heading;
  font-family: var(--font_main);
  color: RGB(var(--theme_heading_3));
  font-size: 1.125rem;
  font-weight: bold;
  margin: 0;
}

h4.heading {
  @include heading;
  font-family: var(--font_main);
  color: RGB(var(--theme_heading_3));
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0;
}