@import '../../../style/text.scss';
@import '../../../style/interaction.scss';

.chip {
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  padding: 0.25rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
  background-color: RGB(var(--theme_button));

  &:last-child {
    margin-right: 0;
  }

  .label {
    @include text_caption;
    line-height: 1rem;
    color: RGB(var(--theme_text_light));
    margin: 0 0.25rem;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 0;
    flex-shrink: 1;
    padding: 0;
    outline: 0;
    margin-right: 0.125rem;
    border-width: 0;
    border-radius: 100%;
    background-color: RGB(var(--theme_button_dark));
    cursor: pointer;
    @include button_hover;
  }
}
