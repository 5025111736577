@import '../../../style/text.scss';
@import '../../../style/interaction.scss';

.tab {
  @include text_body;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem;
  margin: 0;
  background-color: RGB(var(--theme_tab_default));
  border-radius: 0.5rem 0.5rem 0 0;
  outline: 0;
  border-width: 0;
  text-align: center;
  cursor: pointer;
  @include button_hover;

  &.small {
    font-size: 0.75rem;
    font-weight: normal;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0.25rem;
    background-color: RGB(var(--theme_text));
  }

  &.disabled {
    color: RGBA(var(--theme_text), 0.3);
    &:after {
      background-color: RGB(var(--theme_tab_default));
    }
  }

  &.valid {
    color: RGB(var(--theme_tab_success));

    &:after {
      background-color: RGB(var(--theme_tab_success));
    }
  }

  &.invalid {
    color: RGB(var(--theme_tab_error));

    &:after {
      background-color: RGB(var(--theme_tab_error));
    }
  }

  &.active {
    color: RGB(var(--theme_text_light));
    background-color: RGB(var(--theme_text));

    &:after {
      background-color: RGB(var(--theme_text));
    }

    &.valid {
      color: RGB(var(--theme_text_light));
      background-color: RGB(var(--theme_tab_success));

      &:after {
        background-color: RGB(var(--theme_tab_success));
      }
    }

    &.invalid {
      color: RGB(var(--theme_text_light));
      background-color: RGB(var(--theme_tab_error));

      &:after {
        background-color: RGB(var(--theme_tab_error));
      }
    }
  }
}