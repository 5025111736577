@import '_styles/text.scss';

.label {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;

  .text {
    display: block;
    font-size: 0.7rem;
    margin-bottom: 0.25rem;
  }

  .text_bold {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
}