.tab {
  padding: 0 0 1rem 0;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0.25rem;
    background-color: RGB(var(--theme_text));
  }

  &.active {
    color: RGB(var(--theme_button));

    &:after {
      background-color: RGB(var(--theme_button));
    }
  }
}