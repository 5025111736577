@import '../../../style/interaction.scss';
@import '../../../style/text.scss';

.drop {
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 6.25rem;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: RGB(var(--theme_background_module));
  border: 1px solid RGB(var(--theme_border_thin));
  outline: 0;
  z-index: 1;
  @include scroll_bar;

  .row {
    @include text_body;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: stretch;
    height: 2.5rem;
    padding: 0 0.75rem;
    cursor: pointer;

    &:not(:last-child):after {
      content: '';
      position: absolute;
      display: block;
      left: 0.75rem;
      right: 0.75rem;
      bottom: 0;
      height: 1px;
      background-color: RGB(var(--theme_border_thin));
    }

    &.focused {
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: RGBA(var(--theme_row_highlight), 0.75);
      }
    }

    &.selected {
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: RGB(var(--theme_row_highlight));
      }
    }
  }
}
