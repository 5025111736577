@import '_styles/colors.scss';

.layout {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  width: auto;
  padding: 0.5rem;
  background-color: $color_text_light;
  box-shadow: 0.25rem 0 0.5rem rgba(0, 0, 0, 0.3);
  transition: transform 0.5s ease-in;
  transform: translateY(4rem);
  z-index: 1;

  &.in {
    animation-duration: 0.5s;
    animation-name: slideinAction;
    animation-fill-mode: forwards;
  }

  &.out {
    animation-duration: 0.5s;
    animation-name: slideoutAction;
    animation-fill-mode: forwards;
  }
}

@keyframes slideinAction {
  from {
    transform: translateY(4rem);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideoutAction {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(4rem);
  }
}