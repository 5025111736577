@import 'colors.scss';

$row_height: 3rem;

@mixin status {
  &.default {
    border-color: $color_ui;
  }

  &.interacting {
    border-color: $color_brand;
  }

  &.validated {
    border-color: $color_success;
  }

  &.warning {
    border-color: $color_warning;
  }

  &.error {
    border-color: $color_error;
  }

  &.disabled {
    border-color: $color_ui;
    pointer-events: none;
    opacity: 0.5;

    & > * {
      pointer-events: none;
    }
  }
}

@mixin scroll_bar {
  &::-webkit-scrollbar {
    width: 0.75rem;
    background-color: $color_ui_module;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color_brand;
  }

  scrollbar-color: $color_brand $color_ui_module;
  scrollbar-width: thin;
}

@mixin container_small {
  padding: 0.75rem;
}

@mixin container_large {
  padding: 1.25rem;
}

@mixin rounded_small {
  border-width: 1px;
  border-style: solid;
  border-radius: 0.25rem;
}

@mixin rounded_large {
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
}

@mixin table_row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

@mixin table_cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  height: 1.5rem;
  margin: 0.75rem;
}
