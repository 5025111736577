@import '_styles/colors.scss';
@import '_styles/boxes.scss';
@import '_styles/text.scss';

.addBtn {
  flex-grow: 0;
  flex-shrink: 0;
  width: auto;
  cursor: pointer;

  .label {
    color: $color_brand;
    flex-grow: 0;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    .label {
      color: $color_text;
    }
  }
}