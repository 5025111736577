.layout {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 1rem 0rem;
  overflow: hidden;
  pointer-events: none;
  z-index: 30;
}