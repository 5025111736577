@import '_styles/colors.scss';
@import '_styles/text.scss';
@import '_styles/boxes.scss';

.controls {
  padding: 1rem 2rem;
  border-bottom: 1px solid $color_text;
  @include no_select;

  .button {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    opacity: 0.5;
  }

  .title {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    font-weight: bold;
    font-size: 1rem;
    width: 75%;
    margin: 0 1rem;
    text-align: center;
  }
}