@import '../../../style/text.scss';
@import '../../../style/interaction.scss';

.button {
  @include text_body;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 1;
  outline: 0;
  border-width: 0;
  background-color: transparent;
  @include button_hover;
}