@import '../../../style/text.scss';

.options {
  margin: 0;
  padding: 0;
  border-top: 1px solid RGB(var(--theme_text));
  list-style-type: none;

  &.noBorder {
    border-top-width: 0;
  }

  .spacer {
    height: 2.5rem;
  }

  .option {
    @include text_body;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.875rem;
    height: 2.5rem;
    padding-left: 0.5rem;
    border-bottom: 1px solid RGB(var(--theme_border_thin));
    background-color: RGB(var(--theme_background_module));
    cursor: pointer;

    &.selected {
      color: RGB(var(--theme_text_light));
      background-color: RGB(var(--theme_row_selected));
      border-bottom-width: 0;
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: -1rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 1.25rem 0 1.25rem 1rem;
        border-color: transparent transparent transparent RGB(var(--theme_row_selected));
      }

      svg {
        fill: RGB(var(--theme_text_light));
      }
    }

    &.disabled {
      color: RGB(var(--theme_text_disabled));
      background-color: RGB(var(--theme_background_module));
      pointer-events: none;
    }
  }
}
