@import '../../../style/text.scss';
@import '../../../style/interaction.scss';

.layout {
  @include text_body;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 0;
  outline: 0;
  border-width: 0;
  background-color: transparent;
  @include button_hover;

  & > *:nth-child(1) {
    margin-right: 0.5rem;
  }

  &.collapsed {
    & > *:nth-child(1) {
      margin-right: 0;
    }
  }

  &.disabled {
    color: RGB(var(--theme_text_disabled));
  }
}