.layout {
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2rem;
    height: 2rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  }
}