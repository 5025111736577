@import '_styles/text.scss';

@mixin heading {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
}

h1.section {
  @include heading;
  @include headingSection;
  margin-bottom: 3rem;
}

h1.heading {
  @include heading;
  @include headingLarge;
  margin: 0;
}

h2.heading {
  @include heading;
  @include headingMedium;
  margin: 0;
}

h3.heading {
  @include heading;
  @include headingSmall;
  margin: 0;
}

h4.heading {
  @include heading;
  @include headingSmall;
  margin: 0;
}