@import '_styles/colors.scss';

.layout {
  padding: 6rem 4rem;
  height: 100vh;
  background: url('./background-1080p.jpg') center / cover no-repeat;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -12rem;
    left: -10rem;
    width: 60%;
    height: 120%;
    background: rgba(0,0,0,0.77);
    transform: rotate(-15deg);
  }

  .view_content {
    .logo {
      margin-bottom: 6rem;

      svg {
        width: 24rem;
      }
    }
  }
}