@import '_styles/colors.scss';
@import '_styles/text.scss';

.layout {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 2rem 0;

  thead {
    tr {
      border-bottom: 1px solid $color_text;

      th {
        max-width: 15rem;
        height: 3rem;
        padding: 0 0.5rem;
        text-align: left;

        &:last-of-type {
          width: 5rem;
        }

        .head_item {
          display: flex;
          flex-direction: row;
          align-items: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 3rem;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $color_ui;

      &:nth-child(odd) {
        background-color: $color_ui_module;
      }

      td {
        height: 3rem;
        padding: 0 0.75rem;
        text-align: left;
      }
    }
  }

  .body_item {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .selected {
    &:after {
      position: absolute;
      display: block;
      content: '';
      left: 0;
      bottom: 0;
      width: 50%;
      height: 0.25rem;
      background-color: $color_brand;
    }
  }

  .edit {
    display: inline-flex;

    .text {
      color: $color_brand;
      @include no_select;

      &.disabled {
        color: $color_disabled;
      }
    }
  }
}