@import '../../../style/text.scss';
@import '../../../style/interaction.scss';

.tag {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  min-height: 1.5rem;
  border-radius: 0.5rem;
  padding: 0 0.5rem 0 0.25rem;
  margin: 0;
  background-color: RGB(var(--theme_button_disabled));
  border-width: 0;
  outline: 0;
  @include button_hover;

  &.active {
    background-color: RGB(var(--theme_button));
  }
}
