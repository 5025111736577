.layout {
  max-width: 10rem;

  .field {
    min-height: 3rem;

    .input {
      margin: 0.5rem;
    }

    .icon {
      display: flex;
      flex-grow: 0;
      width: auto;
      margin-right: 0.5rem;
      cursor: pointer;
    }
  }
}