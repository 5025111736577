@import'_styles/colors.scss';
@import'_styles/boxes.scss';

.layout {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
  z-index: 100;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
  }

  .content {
    width: 50vw;
    height: auto;
    padding: 2rem;
    background-color: $color_ui_background;
    @include ui_shadow;
    pointer-events: all;

    .field {
      max-width: none;
    }
  }
}