@import '_styles/colors.scss';
@import '_styles/text.scss';
@import '_styles/boxes.scss';

.layout {
  @include no_select;

  .day {
    color: $color_text;
    font-size: 0.8rem;
    flex-grow: 1;
    flex-shrink: 0;
    width: calc(100% / 7);
    height: 3rem;
    padding: 0 0.25rem;
    opacity: 0.5;
  }
}