$flow-list: column row;
$size-list: 'auto' auto, 'fixed' minMax(0, 1fr);

:global .grid {
  display: grid;
  width: 100%;
  align-items: start;

  @each $tuple in $size-list {
    @for $a from 1 through 12 {
      &.col-#{$a}_cell-#{nth($tuple, 1)} {
        grid-template-columns: repeat(#{$a}, nth($tuple, 2));
      }
    }
  }

  @each $tuple in $size-list {
    @for $a from 1 through 12 {
      &.row-#{$a}_cell-#{nth($tuple, 1)} {
        grid-template-rows: repeat(#{$a}, nth($tuple, 2));
      }
    }
  }

  @each $flow in $flow-list {
    &.flow-#{$flow} {
      grid-auto-flow: #{$flow};
    }
  }

  @for $a from 1 through 5 {
    &.col-gap_#{$a} {
      grid-column-gap: #{$a}rem;
    }
  }

  @for $a from 1 through 5 {
    &.row-gap_#{$a} {
      grid-row-gap: #{$a}rem;
    }
  }
}