@import '_styles/colors.scss';
@import '_styles/text.scss';
@import '_styles/boxes.scss';

.layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: all;
}

.content {
  position: absolute;
  top: 0;
  right: 20vw;
  width: 60vw;
  height: 50vh;
  transform: translateY(-100%);
  transition: transform 0.25s ease-out;
  background-color: RGB(var(--theme_background_module));
  @include modal_shadow;

  &.in {
    animation-duration: 0.25s;
    animation-name: slidein;
    animation-fill-mode: forwards;
    pointer-events: all;
  }

  &.out {
    animation-duration: 0.25s;
    animation-name: slideout;
    animation-fill-mode: forwards;
  }
}

@keyframes slidein {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideout {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}