@import '_styles/colors.scss';
@import '_styles/text.scss';

.layout {
  @include text_body;

  &.noMargin {
    margin: 0
  }

  &.bold {
    font-weight: bold;
  }
}