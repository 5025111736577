@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

@import '_styles/colors.scss';

html {
  position: relative;
  font-size: 16px;

  * {
    position: relative;
    box-sizing: border-box;
  }
}

body {
  font-family: 'Roboto', sans-serif;
  min-width: 1280px;
  margin: 0;
  padding: 0;

  :global(#full) {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 9;
  }

  :global(#slide) {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 10;
  }

  :global(#portal) {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 20;
  }

  :global(#notifications) {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 30;
  }

  :global(#splash) {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $color_text;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
