@import '_styles/colors';

.layout {
  height: 3rem;
  padding: 0 1rem;
  cursor: pointer;

  &.enabled {
    background-color: $color_brand_extra_light;
    border-bottom: 1px solid $color_brand;

    &:hover {
      background-color: $color_brand;

      span {
        color: $color_text_light;
      }

      svg * {
        fill: $color_text_light;
      }
    }
  }

  &.disabled {
    background-color: $color_ui_module;
    border-bottom: 1px solid $color_text;
  }

  .locked {
    position: absolute;
    right: 1rem;
    top: 0.75rem;
  }
}
