@import '_styles/colors.scss';
@import '_styles/text.scss';
@import '_styles/boxes.scss';

@mixin button {
  min-height: 2.5rem;
  padding: 0 1.5rem;

  span {
    color: rgb(255,255,255);
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
}

.button_warning {
  @include button;
  border-color: $color_warning;
  background-color: $color_warning;
}

.button_delete {
  @include button;
  border-color: $color_delete;
  background-color: $color_delete;
}

.button_cancel {
  @include button;
  border-color: $color_text;
  background-color: $color_text;
}

.button_save {
  @include button;
  border-color: $color_brand;
  background-color: $color_brand;
}

.button_red {
  @include button;
  border-color: $color_error;
  background-color: $color_error;
}

.button_blue {
  @include button;
  border-color: $color_brand;
  background-color: $color_brand;
}

.button_create {
  @include button;
  border-color: $color_brand;
  background-color: $color_brand;
}

.button_green {
  @include button;
  border-color: $color_success;
  background-color: $color_success;
}

.button_dark {
  @include button;
  border-color: $color_text;
  background-color: $color_text;
}

.button_disabled {
  @include button;
  border-color: $color_disabled;
  background-color: $color_disabled;
  pointer-events: none;
}

.locked {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
}