@import '_styles/colors.scss';
@import '_styles/boxes.scss';

.layout {
  height: 100%;
  overflow: auto;
  @include scroll_bar;

  .wrapper {
    padding: 4rem;
    padding-bottom: 10rem;
    min-height: 100%;
    // overflow: hidden;
  }

  .wrapper_compact {
    min-height: 100%;
  }

  &.no_scroll {
    height: auto;
    overflow: auto;
  }
}