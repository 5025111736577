@import '_styles/text.scss';
@import '_styles/boxes.scss';

.input {
  @include body;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
  margin: 0;
  padding: 0;
  width: 100%;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;

  &::-webkit-input-placeholder {
    color: $color_text_placeholder;
  }

  &:-moz-placeholder {
    color: $color_text_placeholder;
  }

  &::-moz-placeholder {
    opacity: 1;
    color: $color_text_placeholder;
  }

  &:-ms-input-placeholder {
    color: $color_text_placeholder;
  }
}
