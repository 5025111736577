@import '_styles/boxes.scss';
@import '_styles/colors.scss';

.layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: $color_ui;
  border-radius: 0.25rem;

  &.selected {
    border-color: $color_brand;
    background-color: $color_brand;
  }
}