.layout {
  width: 20rem;
  height: 0;
  padding-top: 80%;

  .inner {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    border: 1px solid RGB(var(--theme_outline_default));

    &.selected {
      border-color: RGB(var(--theme_outline_success));
    }
  }

  .icon {
    	position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -1rem;
      left: calc(50% - 1rem);
      width: 2rem;
      height: 2rem;
      background-color: RGB(var(--theme_button_success));
      border-radius: 100%;
      z-index: 1;
  }
}