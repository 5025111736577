@import '../../../style/interaction.scss';
@import '../../../style/text.scss';

.holder {
  position: absolute;
  top: calc(100% + 1rem);
  left: calc(50% - 7rem);
  width: 14rem;
  filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.3));
  z-index: 1;
}

.arrow {
  position: absolute;
  width: 2rem;
  height: 1rem;
  top: calc(-1rem + 1px);
  left: calc(50% - 1rem);
  overflow: hidden;
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    left: calc(0.25rem - 1px);
    bottom: -1rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: RGB(var(--theme_background_module));
    border: 1px solid RGB(var(--theme_border_thin));
    transform: rotate(45deg);
  }
}

.drop {
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 6.25rem;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: RGB(var(--theme_background_module));
  border: 1px solid RGB(var(--theme_border_thin));
  border-radius: 0.5rem;
  outline: 0;
  z-index: 1;
  @include scroll_bar;

  .row {
    @include text_body;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: stretch;
    height: 2.5rem;
    padding: 0 0.75rem;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      display: block;
      left: 0.75rem;
      right: 0.75rem;
      bottom: 0;
      height: 1px;
      background-color: RGB(var(--theme_border_thin));
    }

    &.focused {
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: RGBA(var(--theme_row_highlight), 0.75);
      }
    }

    &.selected {
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: RGB(var(--theme_row_highlight));
      }
    }
  }
}