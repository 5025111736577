@import '_styles/colors.scss';
@import '_styles/text.scss';

$light: mix($color_brand, $color_ui_background, 50%);

.layout {
  display: grid;
  padding: 0 0.25rem 0.25rem 0.25rem;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  @include no_select;

  .day {
    width: 100%;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      padding-top: 100%;
    }

    &.ghosted {
      opacity: 0.3;
    }

    &.disabled {
      cursor: not-allowed;

      & > * {
        color: $color_error;
      }
    }

    /*
      Bar calculation
      % = ((inset = 15) => 100 / ((100 - (inset * 2)) / inset))()
    */

    .selected {
      position: absolute;
      top: 10%;
      left: 10%;
      bottom: 10%;
      right: 10%;
      width: auto;
      overflow: visible;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 100%;
        background-color: $color_brand;
      }

      &.pos_start {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          bottom: 0;
          right: -12.5%;
          background-color: $light;
        }
      }

      &.pos_current {
        &::after {
          background-color: transparent;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -12.5%;
          bottom: 0;
          right: -12.5%;
          background-color: $light;
        }

        &:nth-child(7n + 7) {
          background-color: red;
        }
      }

      &.pos_end {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -12.5%;
          bottom: 0;
          right: 50%;
          background-color: $light;
        }
      }
    }

    .label {
      font-size: 0.8rem;
    }

    .active {
      color: $color_text_light;
      font-size: 0.8rem;
    }

    &:nth-child(7n + 1) {
      .pos_current {
        &::after {
          background-color: $light;
        }

        &::before {
          left: 50%;
        }
      }

      .pos_end::before {
        display: none;
      }
    }

    &:nth-child(7n + 7) {
      .pos_current {
        &::after {
          background-color: $light;
        }

        &::before {
          right: 50%;
        }
      }
    }
  }
}