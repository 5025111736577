@import 'colors.scss';

@mixin headingSection {
  color: $color_brand;
  font-size: 3rem;
  font-weight: 100;
}

@mixin headingLarge {
  color: $color_text;
  font-size: 2rem;
  font-weight: bold;
}

@mixin headingMedium {
  color: $color_text;
  font-size: 1.5rem;
  font-weight: bold;
}

@mixin headingSmall {
  color: $color_text;
  font-size: 1.125rem;
  font-weight: bold;
}

@mixin body {
  color: $color_text;
  font-size: 0.875rem;
  margin: 0 0 1rem 0;
}

@mixin caption {
  color: $color_text;
  font-size: 0.75rem;
  margin: 0 0 0.5em 0;
}

@mixin tiny {
  color: $color_text;
  font-size: 0.625rem;
  margin: 0 0 1em 0;
}

@mixin text_body {
  color: $color_text;
  font-size: 0.875rem;
}

@mixin text_body_bold {
  color: $color_text;
  font-size: 0.875rem;
  font-weight: bold;
}

@mixin text_caption {
  color: $color_text;
  font-size: 0.75rem;
}

@mixin no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}