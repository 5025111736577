@import '../../../style/text.scss';

.layout {
  @include text_body;
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
  margin: 0;

  th, td {
    font-weight: normal;
    text-align: left;
    padding: 0.25rem 0.5rem;
    min-width: 2.5rem;
  }

  thead {
    tr {
      border-bottom: 1px solid RGB(var(--theme_border_thick));
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid RGB(var(--theme_border_thin));
    }
  }

  &.shaded {
    tbody {
      tr:nth-child(even) {
        background-color: RGB(var(--theme_row_shaded));
      }
    }
  }
}