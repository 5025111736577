@import '_styles/colors.scss';
@import '_styles/text.scss';
@import '_styles/boxes.scss';

$color_triangle: rgba(255, 255, 255, 0.5);

.btn {
  @include text_body;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 1;
  outline: 0;
  border-width: 0;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 1rem 1rem;
    border-color: transparent transparent $color_triangle transparent;
  }
}