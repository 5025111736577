.default {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
}

.spaced {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
}

.start {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
}

.end {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: stretch;
}

.spread {
  &> * {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}