// @import '../config/colors.json';

// Test: make sure variables work
// body {
//   background-color: $color_success;
//   color: #333 !important;
// }

$color_brand: rgb(61, 191, 163);
$color_brand_75: rgba(61, 191, 163, 0.75);
$color_brand_50: rgba(61, 191, 163, 0.5);
$color_brand_25: rgba(61, 191, 163, 0.25);
$color_brand_dark: rgb(54, 163, 137);
$color_brand_light: rgb(125, 226, 202);
$color_brand_extra_light: rgb(228, 249, 242);
$color_success: rgb(96, 191, 61);
$color_warning: rgb(191, 166, 61);
$color_error: rgb(193, 44, 9);
$color_delete: rgb(191, 61, 61);
$color_text: rgb(60, 60, 59);
$color_text_mid: rgb(139, 139, 139);
$color_text_light: rgb(255, 255, 255);
$color_disabled: rgba(53, 64, 82, 0.5);
$color_disabled_base: rgb(53, 64, 82);
$color_text_placeholder: rgba(53, 64, 82, 0.5);
$color_list_back: rgb(242,242,242);
$color_list_cell: rgb(235,235,235);
$color_list_cell_border: rgb(222,222,222);
$color_ui: rgb(203, 203, 203);
$color_ui_50: rgba(203, 203, 203, 0.5);
$color_ui_module: rgb(245, 245, 245);
$color_ui_module_inner: rgba(53, 64, 82, 0.06);
$color_ui_background: rgb(255, 255, 255);
$color_ui_background_disabled: rgb(221, 221, 221);

@mixin ui_gradient {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%,rgba(236, 238, 242, 1) 100%)
}

@mixin ui_shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

@mixin modal_shadow {
  filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.3));
}