@import '_styles/colors.scss';
@import '_styles/text.scss';
@import '_styles/boxes.scss';

.layout {
  height: 3rem;
  width: auto;
  padding: 0;
  overflow: visible;

  .clickHandle {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
  }

  .button {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 0.5rem;
    @include ui_gradient;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  .title {
    color: $color_brand;
  }

  .drop {
    position: absolute;
    top: 100%;
    width: 100%;
    max-height: 7.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $color_ui_background;
    border: 1px solid $color_ui;
    outline: 0;
    z-index: 1;
    @include scroll_bar;

    &.expanded {
      max-height: 16.5rem;
    }

    .caddy {
      padding: 0 0.75rem;
    }

    .row {
      font-size: 0.875rem;
      height: 3rem;
      padding: 0 0.75rem;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        display: block;
        left: 0.75rem;
        right: 0.75rem;
        bottom: 0;
        height: 1px;
        background-color: $color_ui
      }

      &.focused {
        &:before {
          content: '';
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba($color_brand, 0.1);
        }
      }

      &.selected {
        &:before {
          content: '';
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba($color_brand, 0.3);
        }
      }
    }

    .expand {
      position: sticky;
      top: 0;
      right: 0;
      width: 1rem;
      height: 1rem;
      background-color: red;
      z-index: 2;
    }
  }
}