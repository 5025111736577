@import '_styles/colors.scss';

.layout {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  align-self: initial;
  width: 1.5rem;
  height: 1.5rem;
  background-color: $color_text;
  border-radius: 100%;
}