@import '_styles/colors.scss';
@import '_styles/text.scss';
@import '_styles/boxes.scss';

.btn {
  @include text_body;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 1;
  outline: 0;
  border: 0px;
  background-color: transparent;
}