@import '_styles/colors.scss';
@import '_styles/boxes.scss';

.layout {
  width: auto;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: $color_ui_background;
  @include rounded_small;
  @include status;

  .message {
    position: absolute;
    font-size: 0.75rem;
    bottom: -1.2rem;
    left: 0;
    height: 1rem;

    &.error {
      color: $color_error;
    }
  }

  .indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color_text_light;
    width: 1rem;
    align-self: stretch;
    flex-grow: 0;
    flex-shrink: 0;

    &.default {
      background-color: $color_ui;
    }

    &.interacting {
      background-color: $color_brand;
    }

    &.error {
      background-color: $color_error;
    }

    &.validated {
      background-color: $color_success;
    }
  }
}